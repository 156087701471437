import React from 'react';
import './Materials.css'
import "react-quill/dist/quill.snow.css";
import {useRecoilState} from "recoil";
import {zoomedAtom} from "../atoms";
import {CreationElement} from "./Creations";

function Contact() {

    const [, setZoomed] = useRecoilState(zoomedAtom);
    return (
        <div className="Materials">
            <div className="Introduction">
                <p>" Choisir de pratiquer la vannerie, c’est se réapproprier un savoir-faire qui a traversé les âges. Elle
                    nous réinscrit dans notre environnement, dans le cycle des saisons qui passent et auxquelles nous
                    devons nous adapter.</p>
                <p>
                    Nous redevenons humbles et écoutons les paroles des ancien.ne.s. Non, le saule, le cornouiller ou
                    encore le châtaignier ne se coupent pas en toute saison. Il faudra attendre l’automne ou l’hiver
                    pour pouvoir en récolter.
                </p>
                <p>Certains végétaux sauvages pourront être tressés presque immédiatement. Pour l’osier, la patience est
                    de mise : il devra sécher plusieurs mois, puis être remis à tremper plusieurs jours ou semaines pour
                    enfin pouvoir être utilisé.</p>
                <p>Le choix des végétaux que nous utilisons est la base de notre travail de vannière. C’est pourquoi
                    l’ensemble de mes créations est réalisé avec des matériaux de qualité provenant de France. "
                </p>
            </div>
            <div className="Content">

                <div className="Items " style={{display: 'flex'}}>
                    <div style={{flex: 2}}>
                        L’osier que j’utilise a été acheté auprès d’osiéricultrices et
                        d’osiériculteurs français.e.s. en fonction des disponiblités de
                        chacun.e et de mes déplacements.<br/><br/>
                        J’ai pour projet de cultiver mon propre osier, proche de mon
                        atelier. C’est un travail qui demande beaucoup d’investissement mais le bonheur de pouvoir tresser l’osier que l’on a vu
                        pousser, que l’on a choyé, récolté puis trié n’a pas de prix.
                    </div>
                    <div style={{flex: 1}}>

                        <img onClick={()=>{setZoomed('/mat_1.jpg')}} style={{width: '100%'}} src='/mat_1.jpg'/>
                    </div>
                </div>
                <br/>
                <div className="Items Invert" style={{display: 'flex'}}>
                    <div style={{flex: 1}}>

                        <img onClick={()=>{setZoomed('/mat_2.jpg')}} style={{width: '100%'}} src='/mat_2.jpg'/>
                    </div>
                    <div style={{flex: 2}}>
                        En automne et en hiver, je récolte différents végétaux sauvages :
                        clématite, cornouiller, châtaignier, noisetier, lierre, troène, viorne
                        lantane, ronce, saule …<br/><br/>
                        Mes récoltes se font alors autour de mon atelier, au gré des
                        balades et des repérages que j’ai effectués au cours de l’année.
                        Je tâche au maximum de ne cueillir que ce dont j’ai besoin.<br/><br/>
                        J’utilise ces matériaux principalement lors des ateliers que j’anime
                        mais également pour certains tressages.
                    </div>

                </div>
                <br/>
                <div className="Items" style={{display: 'flex'}}>
                    <div style={{flex: 3}}>
                        Les bandoulières et poignées en cuir installées
                        sur mes vanneries proviennent de la tannerie{' '}
                        <a href="https://tannerie-bastin.com">Bastin et Fils</a> :<br/><br/>
                        J’ai choisi de me fournir auprès de cette entreprise
                        basée en France et qui propose des cuirs de
                        grande qualité. Leur engagement à porter une
                        attention particulière à la provenance de leurs peaux
                        et tanins végétaux m’a convaincue de ce choix de
                        cohérence.<br/><br/>
                        Leur savoir-faire est lui aussi ancien et ils ont fait le
                        choix de ne pas accélérer leur processus de tannage
                        pour maintenir la qualité de leur travail.<br/><br/>
                        C’est donc un plaisir que de pouvoir travailler avec
                        une tannerie partageant les mêmes valeurs que mon
                        atelier de vannerie.

                    </div>
                    <div style={{flex: 2}}>

                        <img onClick={()=>{setZoomed('/mat_3.jpg')}} style={{width: '100%'}} src='/mat_3.jpg'/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
